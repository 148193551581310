
/* ............header_overall_background_Content.......... */
.signup-color{
  background-color: #2d6a5c;
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width:100%;
  padding-top: 5vh;
  padding-bottom: 5vh;
  border-radius: 0px;
  color: white;
}
.player_wrapper01 {
  position: relative;
  width: 46vh;
  height: 33vh;
  /* margin-left: 0vh; */
  margin-top: -2vh;
  margin-left: -8vh;
  overflow: hidden; /* Ensure the content stays within the wrapper */
}

.react_player01 {
  position: absolute;
  top: 5px;
  left: 25px;
  width: 43vh;
  height: 33vh;
  object-fit: cover; /* Cover the entire area without distortion */
  z-index: 1; /* Place the video at the back */
  background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.7));
}
/* ............button_signin_content........... */
.btn101 {
  background-color: #2ecc71;
  color: white;
  border-radius: 20px;
  font-size: 15px;
  margin-right: 30px;
  padding: 10px 20px;
  border: none;
  font-family: 'Poppins', sans-serif;
  animation: bounceEffect 1s infinite, glowEffect 1.5s infinite;
}

@keyframes bounceEffect {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-15px);
  }
  60% {
    transform: translateY(-10px);
  }
}

@keyframes glowEffect {
  0%, 100% {
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.8);
  }
  50% {
    text-shadow: 0 0 20px rgba(255, 255, 255, 1);
  }
}

/* ................signup_H1_P_Content............. */
 .signup_H1_P_Content span{
  color: #a9a9ac;
  font-size: 49px;
 }  
 .signup_H1_P_Content p{
  color: white;
  font-size: 25px;
 }   
 
 /* ............vedio2_Content........... */
 
.player_wrapper001 {
  position: relative;
  width: 133vh;
  height: 60vh;
 margin-left: -25px;
 margin-top: -16px;
  overflow: hidden; /* Ensure the content stays within the wrapper */
}

.react_player001 {
  position: absolute;
  top: 5px;
  left: 25px;
  width: 100%;
  height: 70vh;
  object-fit: cover; /* Cover the entire area without distortion */
  z-index: 1; /* Place the video at the back */
  background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.7));
}
/* ............signup_Content2......... */
.signup_Content2{
  background-color: #CDCCE8;
  height: 59vh;

  margin-top: -10px;
  margin-left: -2px;
}
.signup_Content2 h4{
  color: #676565;
  margin-top: 1vh;
  margin-left: 8vh;
 
}
.signup_Content2 span{
  color: red;
}
.signup_Content2 h3{
  
  margin-top: 4vh;
  margin-left: 8vh;
}
/* .........button_Content......... */

.btn1 {
  color:  white;
  border-radius: 10px;
  font-weight: 750;
  width: 80%;
  font-size: 20px;
 
  background: #2d6a5c;
  overflow: hidden;
 padding-top: 20px;

 padding-bottom: 20px;
 margin-left: 10vh;

 margin-top: 30px;
}

.btn1:hover {
 
  color:  white;
  box-shadow: 0 0 35px rgb(21, 64, 64);
  background-color: #245638;
}

.btn1::before {
  content: '';
  position: absolute;
  inset: 2px;
  z-index: -1;
  transition: transform 0.5s;
  transform: scaleX(0);
  transform-origin: left;
}

.btn1:hover::before {
  transform: scaleX(1);
}

.btn1 span {
  position: relative;
  z-index: 1;
}

.btn1 span {
  position: absolute;
}

/* .............red_contant1............. */
.red_Content1{
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 7vh;
 
}
.red_Content1 h5{
  color: rgb(220, 46, 46);
  font-size: 30px;
}
.red_Content1 p{
  color: rgb(220, 46, 46);
  font-size: 45px;
}

/* .............red_contant2............. */
.red_Content2{

  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 7vh;
  border-left: 1px solid rgb(0, 0, 0);
  height: 100px;
}
.red_Content2 p{
  color: rgb(220, 46, 46);
  font-size: 23px;
 
}
.red_Content2 h5{
  color: rgb(220, 46, 46);
  font-size: 35px;
  font-weight: 500;
}
/* .............icon_content ............ */
.icon_content001 {
  display: flex;
  align-items: center;
  justify-content: space-around; /* Distributes space evenly */
  text-align: center;
  height: 100px;
  margin-top: 7vh;
  border-left: 1px solid rgb(0, 0, 0);
}

.icon_1_wrapper,
.icon_2_wrapper,
.player_wrapper01_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(255, 255, 255);
  padding: 0px; /* Slightly reduced padding for a more compact look */
  border-radius: 50%;
  width: 110px; /* Set a standard width */
  height: 110px; /* Set a standard height */
}

.icon_1,
.icon_2
{
  height: 110px;
  width: 110px;
  border-radius: 50%;
  padding: 20px;
  background-color: rgb(45,106,92);
  object-fit: cover; /* Ensures the image/video covers the area without distortion */
}
.react_player01_icon {
  height: 110px;
  width: 110px;
  border-radius: 50%;
  padding: 0px;
  background-color: rgb(45,106,92);
  object-fit: cover; /* Ensures the image/video covers the area without distortion */
}
.react_player01_icon {
  border-radius: 50%;
  object-fit: cover;
  z-index: 1; /* Keeps the video at the correct stacking level */
 
}

 .sign-container {
  width:100%;
  height: 40vh;
}
.signup_content p{
  font-size: larger;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 5vh;
  font-weight: 500;
  color: rgb(58, 90, 58);
  font-size: 28px;
  font-family : "nunito",Garamond;
  font-weight: 600;
}
.signup_content span{
  font-size: larger;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 10vh;
  margin-left: 40vh;
  font-family : "nunito",Garamond;
  color: rgb(69, 69,66);
  font-size: x-large;
  font-weight: 600;
}





.signup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 230vh;
  width: 200vh;
  padding-left: 100px;
  margin-top: 240px;
}

.Border_line_under {
  border-bottom: 2px solid rgb(2, 2, 2);
  height: 2px;
  width: 100%;

}



.fireicon{
  margin-top: -5vh;
  margin-left: -5vh;
}

.signup-color h1{
  padding-right: 1px;
  font-size: 38px;
}
.signup-color h3{
  padding-bottom: 10px;
}
 .logo_image img{
  height: 30vh;
  margin-left: 0.5px;
 }

.sign-container h4 {
  font-size: 45px;
  font-weight: 700;
  color: #7ecdaa;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
 
}
.Vedio_Content{
  padding: 20px;
}
.Vedio_Content img{
  width: 70vh;
  height: auto;
  border-radius: 10px;
}
.label-float {
  position: relative;
  padding-top: 13px;
  margin-bottom: 15px;
}
.label-float input,
.label-float select {
  border: 1px solid #CDCCE8;
  border-radius: 5px;
  outline: none;
  min-width: 250px;
  padding: 15px 20px;
  font-size: 16px;
  transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -webkit-appearance: none;
  width: 98%;
}
.label-float input:focus,
.label-float select:focus {
  border: 2px solid #11351E ;
}
.label-float input::placeholder,
.label-float select option:first-child {
  color: transparent;
}
.label-float label {
  pointer-events: none;
  position: absolute;
  top: calc(50% - 8px);
  left: 15px;
  transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  background-color: white;
  padding: 5px;
  box-sizing: border-box;
}
.label-float input:required:invalid + label,
.label-float select:required:invalid + label {
  color: #37754d ;
}
.label-float input:focus:required:invalid,
.label-float select:focus:required:invalid {
  border: 2px solid #0f2416;
}
.label-float input:required:invalid + label:before,
.label-float select:required:invalid + label:before {
  content: "*";
}
.label-float input:focus + label,
.label-float input:not(:placeholder-shown) + label,
.label-float select:focus + label,
.label-float select:not([value=""]) + label {
  font-size: 13px;
  top: 0;
  color:  #11351E;
}
 .close-button{
    border: none;
    margin-left: 100%;
    font-size: 25px;
    margin-top: -20px;
    background-color: white;
    color: #CDCCE8;
}

.Join-button  {
  background-color: rgb(58, 90, 58);
  color: #ffffff;
  border: rgb(255, 255, 255);
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 15px 32px;
  display: inline-block;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  height: 60px;
  border-radius: 100px;
  width: 100%;
}


.Join-button:hover {
  background-color: rgb(58, 90, 58);
}

.toggle-Password {
  position: absolute;
  top: 43%;
  right: 30px;
  cursor: pointer;
  color: #7cb89d;
  font-size: 16px;
  font-weight: 700;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.signup-container a {
  text-decoration: none;
  color: #7cb89d
}

.signup-card p{
  color: #11351E ;
}

.google-image {
  width: 20px;
  margin-right: 8px;
  height: auto;
}

.google-button {
  background-color: #fafcfd;
  color: #000000;
  border: 1px solid #ccc;
  border-radius: 100px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  height: 50px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.google-button:hover {
  background-color: #d9e7ee;
}

.checkbox ,.yes, .option{
  font-size: 5px;
  display: flex;
}



@keyframes text {
  0%{
    color:white;
    margin-bottom:-5px;
  }
  0%{
    letter-spacing:20px;
    margin-bottom:-10px;
  }
  5%{
    letter-spacing:3px;
    margin-bottom:-10px;
  }
}


.bi-fire{
  font-size: 70px;
  color: rgb(58, 90, 58);
  padding-left: 25vh;
 padding-top: 7vh;
  
}

.content_00001 {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2; /* Place the content in front of the video */
  color:rgb(69, 69, 66);
  text-align: center;
  margin-bottom: -50px;
  font-family: "nunito", Garamond ;
 

/* Garamond ,   "Gabriola"*/


}

.content_00001 h1 {
  font-size: 30px;
  font-weight: 400;
  margin-bottom: -100px;
}





@media (max-width: 760px) {
 
    .sign-container {
      width: 100%;
      height: auto; /* Allow height to adjust to content */
    }
  
    .signup-color {
      flex-direction: column; /* Stack elements vertically */
      padding-top: 3vh;
      padding-bottom: 3vh;
     }
  
  .player_wrapper01 {
      width: 160px ;
      height: 110px;
      margin-top: 1vh;
      margin-left: 10vh;
      margin-right: 15vh;
    }
  
    .react_player01 {
      
      width: 130px ;
      height: 110px;
    } 
    .signup_H1_P_Content {
      padding: 2vh 5vw; 
      text-align: center; 
    }
    
    .signup_H1_P_Content span{
      text-align: center; 
      font-size: x-large;
    }
    .signup_H1_P_Content p{
      text-align: center; 
      font-size: large;
    }
  
    .btn101 {
     
      padding: 8px 16px; 
      font-size: 14px; 
    }
  
  /* Radium Effect and Color Rotate Animations */
  @keyframes radiumEffect {
    0% {
      box-shadow: 0 0 5px rgba(85, 85, 85, 0.5);
    }
    50% {
      box-shadow: 0 0 15px rgba(85, 85, 85, 0.5), 0 0 30px rgba(255, 105, 180, 0.7), 0 0 45px rgba(255, 255, 255, 0.9);
    }
    100% {
      box-shadow: 0 0 5px rgba(85, 85, 85, 0.5);
    }
  }
  
  @keyframes colorRotate {
    0% {
      background-color: white;
      color: rgb(34, 34, 34);
    }
    25% {
      background-color: rgb(0, 128, 255);
      color: white;
    }
    50% {
      background-color: rgb(255, 165, 0);
      color: rgb(34, 34, 34);
    }
    75% {
      background-color: rgb(255, 69, 0);
      color: white;
    }
    100% {
      background-color: white;
      color: rgb(34, 34, 34);
    }
  }

  .label-float input,
  .label-float select {
    width: 100%;
    font-size: 14px;
  }

  .Join-button {
    width: 100%;
    padding: 10px;
  }
  .d-flex {
    flex-direction: column;
  }


  .player_wrapper001 {
    margin-top: 0vh;
    height: 60vh; 
    width: 46vh;

  }
  .signup_Content2{
    margin-top: 0vh;
    padding-top: 2vh;
    height: 30vh; 
    width: 100%;
  }

  .signup_Content2 h4,
  .signup_Content2 h3 {
    font-size: 16px;
    margin-left: 10px;
    align-items: center;
   
  }

  .btn1 {  
    color:  white;
    border-radius: 10px;
    font-weight: 750;
    font-size: 15px;
    background: #2d6a5c;
    overflow: hidden;
   padding-top: 0px;
   padding-bottom: 0px;
   margin-left: 2vh;
   margin-top: 10px;
  }

  
    .red_Content1 {
      width: 100%;
      margin-top: 2vh;
      height: 10vh;
    } 
    .red_Content2 {
      width: 100%;
      margin-top: 2vh;
      height: 10vh;
    }
    
    .icon_content001 {
      width: 100%;
      margin-top: -20vh; 
      height: 90vh;
      flex-direction: column; 
      justify-content: center;
      align-items: center; 
      margin-bottom: -19  vh;
    }
    
    .icon_1_wrapper,
    .player_wrapper01_icon,
    .icon_2_wrapper {
      margin-bottom: 10px; /* Space between elements */
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    .icon_1,
    .react_player01_icon,
    .icon_2 {
      height: 10vh; 
      width: 10vh;
      object-fit: cover; 
    }
  
    .icon_1_wrapper img,
    .react_player01_icon,
    .icon_2_wrapper img {
      height: 10vh; 
      width: 10vh; 
    }
  
    h3 {
      margin: 10px 0; 
      font-size: 24px;
    }
    .red_Content2 {
      margin-top: 3vh; 
      padding: 15px; 
    }
  
    .red_Content2 p, .red_Content2 h5 {
      font-size: 18px; 
    }
    
   .btn-12 {
      font-size: 10px; 
      padding: -30px 10px;
     
  }
  
  .btn-12 span {
      width: 30px; 
      height: 10px; 
      padding: 10px 25px;
  }
  }
  
  .btn-11 {
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    border: none;
    background: rgb(136, 136, 136);
    background: linear-gradient(0deg, white 0%,white 100%);
    color: rgb(45,106,92);
    overflow: hidden;
    padding: 10px 25px;
    border-radius: 5px;
    margin-top: 18px;
    margin-left: 20px;
    font-size: 22px;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    transition: opacity 0.3s;
}

.btn-11:hover {
    text-decoration: none;
    color: #680303;
    opacity: 0.7;
}

.btn-11:before {
    position: absolute;
    content: '';
    display: inline-block;
    top: -180px;
    left: 0;
    width: 30px;
    height: 100%;
    background-color: rgb(45,106,92);
    animation: shiny-btn1 2s ease-in-out infinite;
}

.btn-11:active {
    box-shadow: 
        4px 4px 6px 0 rgba(255, 255, 255, 0.3),
        -4px -4px 6px 0 rgba(116, 125, 136, 0.2),
        inset -4px -4px 6px 0 rgba(255, 255, 255, 0.2),
        inset 4px 4px 6px 0 rgba(0, 0, 0, 0.2);
      
}

@keyframes shiny-btn1 {
    0% { 
        transform: scale(0) rotate(45deg); 
        opacity: 0; 
    }
    80% { 
        transform: scale(0) rotate(45deg); 
        opacity: 0.5; 
    }
    81% { 
        transform: scale(4) rotate(45deg); 
        opacity: 1; 
    }
    100% { 
        transform: scale(50) rotate(45deg); 
        opacity: 0; 
    }
}

/* -webkit- prefix for older browser support */
@-webkit-keyframes shiny-btn1 {
    0% { 
        -webkit-transform: scale(0) rotate(45deg); 
        opacity: 0; 
    }
    80% { 
        -webkit-transform: scale(0) rotate(45deg); 
        opacity: 0.5; 
    }
    81% { 
        -webkit-transform: scale(4) rotate(45deg); 
        opacity: 1; 
    }
    100% { 
        -webkit-transform: scale(50) rotate(45deg); 
        opacity: 0; 
    }
}

  .btn-12{
    position: relative;
    left: 50px;
    top: 20px;
    bottom: 20px;
    border:none;
    box-shadow: none;
    width: 70vh;
    height: 60px;
    line-height: 62px;
    -webkit-perspective: 230px;
    perspective: 230px;
    background-color: rgb(205,204,232);
    font-size: x-large;
    color: white;
    
  }
  .btn-12 span {
    background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgb(45,106,92) 0%, rgb(45,106,92)100%);
    display: block;
    position: absolute;
    width: 70vh;
    height: 60px;
    box-shadow:inset 2px 2px 2px 0px rgba(0,0,0,.1),
     7px 7px 20px 0px rgba(0,0,0,.1),
     4px 4px 5px 0px rgba(0,0,0,.1);
    border-radius: 5px;
    color:white;
    margin:0;
    text-align: center;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all .3s;
    transition: all .3s;
  }
  .btn-12 span:nth-child(1) {
    box-shadow:
     -7px -7px 20px 0px #0001,
     -4px -4px 5px 0px #0001,
     7px 7px 20px 0px #0002,
     4px 4px 5px 0px #0001;
    -webkit-transform: rotateX(90deg);
    -moz-transform: rotateX(90deg);
    transform: rotateX(90deg);
    -webkit-transform-origin: 50% 50% -20px;
    -moz-transform-origin: 50% 50% -20px;
    transform-origin: 50% 50% -20px;
  }
  .btn-12 span:nth-child(2) {
    -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: 50% 50% -20px;
    -moz-transform-origin: 50% 50% -20px;
    transform-origin: 50% 50% -20px;
  }
  .btn-12:hover span:nth-child(1) {
    box-shadow:inset 2px 2px 2px 0px #0001,
     7px 7px 20px 0px rgba(0,0,0,.1),
     4px 4px 5px 0px rgba(0,0,0,.1);
    -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
  .btn-12:hover span:nth-child(2) {
    box-shadow:inset 2px 2px 2px 0px rgb(45,106,92),
     7px 7px 20px 0px rgb(45,106,92),
     4px 4px 5px 0px rgb(45,106,92);
   color: transparent;
    -webkit-transform: rotateX(-90deg);
    -moz-transform: rotateX(-90deg);
    transform: rotateX(-90deg);
  }
  
.btn-12:before {
  position: absolute;
  content: '';
  display: inline-block;
  top: -180px;
  left: 0;
  width: 30px;
  height: 100%;
  background-color: rgb(45,106,92);
  animation: shiny-btn1 3s ease-in-out infinite;
}

.btn-12:active {
  box-shadow: 
      4px 4px 6px 0 rgba(255, 255, 255, 0.3),
      -4px -4px 6px 0 rgba(116, 125, 136, 0.2),
      inset -4px -4px 6px 0 rgba(255, 255, 255, 0.2),
      inset 4px 4px 6px 0 rgba(0, 0, 0, 0.2);
    
}

@keyframes shiny-btn1 {
  0% { 
      transform: scale(0) rotate(45deg); 
      opacity: 0; 
  }
  80% { 
      transform: scale(0) rotate(45deg); 
      opacity: 0.5; 
  }
  81% { 
      transform: scale(4) rotate(45deg); 
      opacity: 1; 
  }
  100% { 
      transform: scale(50) rotate(45deg); 
      opacity: 0; 
  }
}

/* -webkit- prefix for older browser support */
@-webkit-keyframes shiny-btn1 {
  0% { 
      -webkit-transform: scale(0) rotate(45deg); 
      opacity: 0; 
  }
  80% { 
      -webkit-transform: scale(0) rotate(45deg); 
      opacity: 0.5; 
  }
  81% { 
      -webkit-transform: scale(4) rotate(45deg); 
      opacity: 1; 
  }
  100% { 
      -webkit-transform: scale(50) rotate(45deg); 
      opacity: 0; 
  }
}
  